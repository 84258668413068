<template>
  <div class="define-menu-detail2-wrapper">
    <van-nav-bar
      title="详情"
      :border="false"
      left-arrow
      fixed
      @click-left="$router.back()"
    />
    <div class="config-list">
      <van-empty
        v-if="list.length === 0"
        image="search"
        description="查无数据"
      />
      <div v-for="(c, i) in list" :key="i" class="config-field">
        <span class="config-field-label">{{ c.label }}: </span>
        <span class="config-field-value">{{ c.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefineMenuDetail2",
  props: {},
  data() {
    return {
      list: []
    };
  },
  created() {
    this.list = this.$route.params.list.originList;
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.define-menu-detail2-wrapper {
  padding-top: 60px;
  .search-total {
    text-align: center;
    font-size: 12px;
    color: #8c8f97;
    padding-top: 12px;
  }
  .config-list {
    padding: 12px 16px;
    .config-field {
      height: 20px;
      line-height: 20px;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      &-label {
        display: inline-block;
        min-width: 68px;
        color: #8c8f97;
        margin-right: 8px;
      }
      &-value {
        color: #2e2e4d;
      }
    }
  }
}
</style>
